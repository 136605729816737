import React, { useEffect, useState } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import workinglab from "Assests/Images/woman-working-lab-with-microscope.png";
import vision from "Assests/Images/businessman-hand-holding-lightbulb-with-glowing-light-creative-smart-thinking-inspiration-innovation-with-network-concept 1.png";
import mission from "Assests/Images/people-using-digital-device-while-meeting 1.png";
import icon1 from "Assests/Images/icon1.svg";
import physicalwellness from "Assests/Images/physicalwellness.jpg";

import httpPathName from "Global/Config/Config";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";

const About = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAbout = async () => {
    const apiStatus = await httpPathName
      .get(`admin/content/all-aboutus`)
      .then((response) => {
        console.log("respoinse", response);
        setData(response?.data?.Allcontent[0]);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetched Error"));
      });
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <><Header />
    <div className="about1">
      <div className="section5">
        <div className="content">
          <div className="content-left">
            <h3>Physical Wellness</h3>
            {/* <img className="wanner3dot" src={dot3} alt="dot3" /> */}
            
            <p>At the heart of our wellness approach is a commitment to your physical health. We offer a comprehensive suite of services designed to keep you at your best, with personalized care tailored to your needs.</p>
          </div>

          <div className="img-container right">
            <img
              className=""
              src={physicalwellness}
              alt="physicalwellness" />
          </div>
        </div>
      </div>

      
      <div className="section4-wrapper">
      <h3>Fitness & Nutrition Tracker</h3>
      <p>Stay on top of your fitness and nutrition goals by capturing every progress. Monitor your physical activity, nutrition & hydration, set goals, and receive personalized recommendations to maintain a balanced and healthy lifestyle.</p>

      </div>

      <div className="section4-wrapper">
      <h3>Online Diet Counselling</h3>
      <p>Connect with our Dietician online and achieve health goals through personalized nutrition advice delivered conveniently. Ideal for individuals with busy schedules who may find it challenging to attend in-person consultations. Now you can choose what serves you the best while benefiting from our expert guidance and ongoing support.</p>

      </div>
     




    </div>
    <Footer />
    </>
    
  );
};

export default About;
