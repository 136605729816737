import React, { useState, useRef, useEffect } from "react";
import "./Corporate.css";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import httpPathName from "Global/Config/Config";
import { ReactComponent as CartIcon } from "Assests/Icons/cartIcon.svg";
import { bookNowApi } from "API/post.api";
import { setAuthenticationModel } from "../../Redux/Actions/model";
import { useNavigate } from "react-router";
import diagnostics from "Assests/Images/diagnostics.png";
import Spinner from "react-bootstrap/Spinner";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import partnerWithUs from "Assests/Images/partnerWithUs.png";
import Wanner from "Components/Wnner/Wanner";

const nav = [
  "Annual Physical Examination",
  "Pre-Employment Checkup",
  "Preventive Health Packages",
  // "OncoNed",
];

const Corporate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [expression, setExpression] = useState(generateRandomKeyword());
  const [captcha, setCaptcha] = useState("");
  const formRef = useRef(null);
  const canvasRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const token = localStorage.getItem("access_token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Our corporate healthcare solutions elevate healthcare standards","Explore our corporate solutions designed for optimized healthcare of your employees. Collaborate with us to boost healthcare standards within your organization.")

  const fetchEmployData = async () => {
    setLoading(true);

    const apiStatus = await httpPathName
      .get("home/getEmploymentCheckTestList", {
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        // dispatch(setAlert("success", response?.data?.message));
        setData(response?.data?.data);
        setLoading(false);
        //setShow(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEmployData();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Clear previous content
    context.clearRect(0, 0, canvas.width, canvas.height);
    // Set text properties
    context.font = "30px Arial";
    context.fillStyle = "blue";
    context.textAlign = "center";
    context.textBaseline = "middle";
    // Draw CAPTCHA text
    context.fillText(expression, canvas.width / 2, canvas.height / 2);
  }, [expression]);

  function generateRandomKeyword() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let keyword = "";

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      keyword += characters.charAt(randomIndex);
      // setExpression(keyword);
    }

    return keyword;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    console.log("formdata", formData);
    if (
      formData?.mobile_number &&
      !isValidPhoneNumber(`+${formData?.mobile_number}`)
    ) {
      dispatch(setAlert("error", "Please enter a valid mobile number"));
    } else if (Object.keys(formData).length < 6) {
      dispatch(setAlert("error", "All fields are required!"));
    } else if (captcha !== expression) {
      dispatch(setAlert("error", "Please enter a valid Captcha Code"));
    } else {
      const promise = await httpPathName
        .post(
          `corporate/postCorporateDetails`,
          { ...formData },
          { headers: { "x-auth-token": token } }
        )
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            dispatch(setAlert("success", response?.data?.message));
            formRef.current.reset();
            setFormData({});
            setCaptcha("");
          } else {
            dispatch(setAlert("error", response?.response?.data?.message));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAlert("error", err?.response?.data?.message));
        });
    }
  };

  const CardItem = ({
    img,
    alt,
    title,
    short_desc,
    price,
    original_price,
    include,
    parameter,
    long_desc,
    key,
    id,
  }) => {
    return (
      <div
        // onClick={() => navigate(`/blog/${title}`)}
        className="card-item"
      >
        <img src={img || diagnostics} alt={alt} />
        <div className="main-content">
          <h6>{title || "No Title"}</h6>
          {/* <p className="description">{short_desc || "No Description"}</p> */}
          <span>₱ {price}</span>
          <button
            className="book-btn flexRowAlignJustify"
            onClick={(e) => {
              e.stopPropagation();
              if (!token) {
                // Replace False with true to Enable Auth Modal
                dispatch(setAuthenticationModel(false));
              } else {
                const apiStatus = bookNowApi(token, id);
                apiStatus
                  .then((response) => {
                    console.log(response);
                    navigate("/cart");
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(setAlert("error", err?.response?.data?.error));
                  });
              }
            }}
          >
            <CartIcon style={{ marginRight: "8px" }} /> Book Now
          </button>
        </div>
      </div>
    );
  };

  function section1() {
    return (
      <>
        <div className="section1-warpper">
          <h1>Wellness Packages</h1>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="8"
            viewBox="0 0 50 8"
            fill="none"
          >
            <rect width="8" height="8" rx="4" fill="#2A364E" />
            <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
            <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
          </svg> */}
          <p>
            Stress is good as it keeps oneself busy and help to reach the goal.
            But when stress becomes routine part of the life, it could affect
            one’s health severely. Work pressure can lead to chronic diseases
            like diabetes, insomnia, obesity, hypertension and even heart
            disease as well. It is observed that people who are under stress
            often move towards unhealthy habits like munching on junk food, drug
            abuse, alcohol, cigarettes etc. Being pathology specialists, we
            understand the need and to bridge this gap, we offer services that
            are flexible and customized to suit the requirements of our client.
            These health packages are comprehensive and preventive, which helps
            to monitor and takes care of well-being of employee. As it is
            rightly said “Health is wealth”, many organizations are taking
            special care for their employees, by introducing corporate wellness
            programs. This is a win-win situation for both the employers and the
            employees. This helps the company to monitor the health of its
            workforce and avoid absenteeism and underperformance along with
            employee retention. On the other hand employees contribute to the
            company in a productive and efficient manner.
          </p>
        </div>
      </>
    );
  }

  function section2() {
    return (
      <>
        <div className="section2-Wrapper">
          <h1>
            Our <span style={{ fontWeight: "500" }}>Offerings</span>
          </h1>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="8"
            viewBox="0 0 50 8"
            fill="none"
          >
            <rect width="8" height="8" rx="4" fill="#2A364E" />
            <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
            <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
          </svg> */}
          <div className="offeringTabWrapper">
            {nav.map((item, i) => (
              <div
                onClick={() => setSelected(i)}
                className={`${
                  i == selected ? "offeringTab" : "offeringTab-n-Sel"
                }`}
                key={i}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  function section3() {
    return (
      <>
        <div>
          {data?.length > 0 ? (
            <div className="cardWrapper">
              {data?.slice(0, 4).map((item, i) => (
                <CardItem
                  key={i}
                  img={item?.image}
                  alt={item?.test_name}
                  title={item?.test_name}
                  short_desc={item?.intented_use}
                  price={item?.mrp}
                  original_price={item?.price}
                  include={7}
                  parameter={76}
                  id={item?._id}
                />
              ))}
            </div>
          ) : !!loading ? (
            <div
              className="d-flex justify-content-center"
              style={{ margin: "auto", width: "100%" }}
            >
              <Spinner />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ margin: "auto", width: "100%" }}
            >
              No Data Found
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <MetaDetails
        title="Our corporate healthcare solutions elevate healthcare standards"
        description="Explore our corporate solutions designed for optimized healthcare of your employees. Collaborate with us to boost healthcare standards within your organization."
      />

      <Header />
      <div className="corporate">
        <Wanner title="Corporate" image={partnerWithUs} dot={false} />

        {/* <div className="partnerTitlemiddle">
          <h1>Corporate</h1>
          <img src={dot3} alt="dot3" />


        </div> */}
        <div className="section1">{section1()}</div>
        <div className="section2">{section2()}</div>
        {/* <div className="section3">{section3()}</div> */}
        <form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="formWrapper"
        >
          <p className="we-happy">
            We are happy to hear from you! Kindly fill in the below details and
            we will get back to you.
            <br />
            <p className="we-happy">Thanks!</p>
          </p>
          <p className="title">Business Details</p>
          <div className="business">
            <div className="input-wrapper">
              <div>
                <p>Your Name *</p>
                <input
                  name="name"
                  // value={formData?.firstName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Your Name *"
                />
              </div>
              <div>
                <p>Company Name *</p>
                <input
                  name="company_name"
                  // value={formData?.lastName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Company Name *"
                />
              </div>
              <div>
                <p>Email Id *</p>
                <input
                  name="email_id"
                  // value={formData?.lastName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Email Id *"
                />
              </div>
              <div>
                <p>Mobile Number *</p>
                <input
                  name="mobile_number"
                  // value={formData?.lastName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Mobile No *"
                />
              </div>

              <div>
                <p>Select Enquiry *</p>
                <select
                  name="enquiry"
                  required
                  // value={formData?.gender}
                  onChange={(e) => handleChange(e)}
                  id="enquiry"
                >
                  <option hidden value="">
                    Select Enquiry
                  </option>
                  <option value="Annual Physical Examination">
                    Annual Physical Examination
                  </option>
                  <option value="Pre-Employment Medical Examination">
                    Pre-Employment Medical Examination
                  </option>
                  <option value="Wellness Packages">Wellness Packages</option>
                  <option
                    value="Corporate Camps
"
                  >
                    Corporate Camps
                  </option>
                </select>
              </div>

              <div>
                <p>Select City</p>
                <select
                  required
                  name="city"
                  // value={formData?.gender}
                  onChange={(e) => handleChange(e)}
                  id="city"
                >
                  <option hidden value="">
                    Select City
                  </option>
                  <option value="Quezon">Quezon</option>
                </select>
              </div>

              <div>
                <p>Enter The Captcha</p>
                <input
                  type="text"
                  value={captcha}
                  onChange={(e) => setCaptcha(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1%",
                    gap: "4%",
                  }}
                >
                  <canvas ref={canvasRef} width={120} height={40}></canvas>
                  <svg
                    style={{ cursor: "pointer" }}
                    onClick={() => setExpression(generateRandomKeyword())}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 8.5C5 5 8.51657 3.00122 11.9991 3.00122C16.9692 3.00122 20.9982 7.03039 20.9982 12.0006C20.9982 16.9708 16.9692 21 11.9991 21C8.51657 21 5.49605 19.0217 3.99995 16.1276M3 8.5V4.00006M3 8.5H7"
                      stroke="#131A29"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Corporate;
