import React, { useEffect, useState } from "react";
import searchIcon from "Assests/Icons/searchIcon.svg";
import rightarrowwhite from "Assests/Icons/rightarrowwhite.svg";
import download from "Assests/Icons/download.svg";
import httpPathName from "Global/Config/Config";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Search from "Components/Common/Search/Search";
import RiderDetails from "Components/Popup/RiderDetails/RiderDetails";
import CancelRequestModal from "Components/Popup/CancelRequestModal/CancelRequestModal"; 
// import Modal from "react-modal";


const History = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const [historyData, setHistoryData] = useState([]);
  const [search, setSearch] = useState("");
  
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedtest, setSelectedTest] = useState(null);


  const [selectedRider, setSelectedRider] = useState(null);

  const handleShowRiderDetails = (rider, appnum) => {
    rider['appoimentNumber'] = appnum ? appnum : null;
    setSelectedRider(rider);
    setShow(true); // Open the modal
  };

  let serialNumber = 1;

  const getHistory = async () => {
    setLoading(true);
    const apiStatus = await httpPathName
      .get(`users/history-orders?testName=${search}`, {
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        if (response?.status === 200) {
          setHistoryData(response?.data?.History);
          console.log('historyData', historyData)
        } else {
          dispatch(setAlert("error", response?.response?.data?.message));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "History Fetched Error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleCancelRequest = (item) => {
  //   console.log("Cancel Request for:", item);
  //   // Add your logic here
  // };

  const handleCancelRequestClick = (item) => {
    if (item) {
      setSelectedItem(item); // Save the selected item in state
      const userConfirmed = window.confirm("Are you sure you want to cancel the order?");
      if (userConfirmed) {
        handleCancel(item); // Pass the item to handleCancel
      }
    } else {
      console.error("No item found to cancel.");
    }
  };

  const handleCancel = async (item) => {
    console.log("Cancel for:", item);
    console.log("Cancel Request for:", item);
    if (!item) {
      console.error("Item is undefined or null. Cannot proceed with cancel.");
      return;
    }

    try {
      const token = localStorage.getItem("access_token"); // Fetch token from local storage

      if (!token) {
        alert("User is not authenticated. Please log in.");
        return;
      }

      // Set loading state to true if applicable
      setLoading(false);

      const response = await httpPathName.post(
        "users/nd-cancel-order",
        item,
        {
          headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Cancel request successful:", response.data);

        
        window.location.reload();
      } else {
        // console.error("Failed to cancel request:", response.data);
        // alert("Failed to cancel request");
        dispatch(setAlert("error", response?.response?.data?.message));
      }
    } catch (err) {
      console.error("Error while sending cancel request:", err);
      dispatch(setAlert("error", "Failed to cancel the request. Please try again."));
    } finally {
      // Reset loading state
      setLoading(true);
    }
    // Add your logic here
  };

  const handleSendRequest = (item) => {
    setSelectedTest(item); // Save the selected item
    setShowModal(true); // Show the modal
  };
  
  const handleConfirmCancelRequest = async (reason) => {
    if (!selectedtest) {
      console.error("No item selected for cancellation.");
      return;
    }

  console.log("Selected Item:", selectedtest);
  console.log("Cancellation Reason:", reason);
  
    const payload = {
      ...selectedtest,
      cancelReason: reason,
    };
  
    try {
      const token = localStorage.getItem("access_token");
  
      if (!token) {
        alert("User is not authenticated. Please log in.");
        return;
      }
      setLoading(false);
      const response = await httpPathName.post(
        "users/cancel-request",
        payload,
        {
          headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        console.log("Cancel request successful:", response.data);

        
        window.location.reload();
      } else {
        // console.error("Failed to cancel request:", response.data);
        // alert("Failed to cancel request");
        dispatch(setAlert("error", response?.response?.data?.message));
      }
    } catch (err) {
      console.error("Error while sending cancel request:", err);
      dispatch(setAlert("error", "Failed to cancel the request. Please try again."));
    } finally {
      // Reset loading state
      setLoading(true);
    }
    // Add your logic here
  };
  
 

  const handleInvoice = async (ref_id, order_id) => {
    // You can access 'data' here
    // console.log("Data received:", ref_id);
    const apiData = await httpPathName
      .get(`users/order-invoice?orderRefId=${ref_id}&testOrderedId=${order_id}`, {
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.cms_status === 1) {
            // console.log("response....",response);
            dispatch(setAlert("success", response?.data?.message));

            let invoice_link = response?.data?.invoice_link
            if (invoice_link && invoice_link !== '') {
              window.open(invoice_link, '_blank', 'noopener,noreferrer');
            }

          }
          else {
            dispatch(setAlert("error", response?.data?.message));
          }
        } else {
          dispatch(setAlert("error", response?.data?.message));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "CMS Not Connect"));
      })
    // .finally(() => {
    //   setLoading(false);
    // });
    // Perform your logic
  };

  useEffect(() => {
    getHistory();
  }, [search]);


  return (
    <div className="history">
      <div className="top">
        <p className="overallHistory">Overall History</p>
        <Search onChange={(e) => setSearch(e.target.value)} />
      </div>

      <table>
        <thead>
          <tr>
            <th style={{ padding: "1%" }}>S.No</th>
            <th>Date</th>
            <th>Name</th>
            <th>Package</th>
            <th>Order Status</th>
            <th>Amount</th>
            <th>Rider</th>
            <th>Report</th>
            <th>Order Receipt</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!loading && historyData?.length > 0 ? (
            historyData.map((item, i) => (
              <React.Fragment key={i}>
                {item.map((d, t) => (
                  <tr key={t}>
                    <td>{serialNumber++}</td>
                    <td>{d?.date}</td>
                    <td>{d?.patientName}</td>
                    <td
                      className="two-line-clamp-unset"
                      style={{ width: "14%" }}
                    >
                      {d?.testName}
                    </td>
                    {/* <td>{d?.orderStatus}</td> */}
                    <td>
                    {d?.orderStatus}
                    {d?.orderStatus == 'Rider Rejected' && (
                      <div style={{ color: 'red', marginTop: '5px' }}>
                        {d?.riderRejectedReason}
                      </div>
                    )}
                  </td>
                    <td>
                      ₱{d?.amount}{" "}
                      {d?.paymentStatus === "Unpaid" ? (
                        <span className="unpaid">(Unpaid)</span>
                      ) : (
                        <span className="paid">(Paid)</span>
                      )}
                    </td>
                    <td>
                      <div>
                        {d?.riders && d?.riders?.length > 0 ? (
                          d.riders.map((rider) => (
                            <span
                              onClick={() => handleShowRiderDetails(rider, d?.appoimentNumber)} style={{ cursor: "pointer" }}
                              key={rider._id}
                            >
                              {d?.orderStatus === "Cancelled" ? (
                                  "-" // Show "-" for Cancelled orders
                                ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                <path fill="#000" d="M16 1.2c-1 0-1.8.8-1.8 1.8S15 4.8 16 4.8S17.8 4 17.8 3S17 1.2 16 1.2m-3.6 2.9c-.47 0-.9.19-1.2.5L7.5 8.29C7.19 8.6 7 9 7 9.5c0 .63.33 1.16.85 1.47L11.2 13v5H13v-6.5l-2.25-1.65l2.32-2.35L14.8 10H19V8.2h-3.2l-1.94-3.27c-.29-.5-.86-.83-1.46-.83M10 3H3c-.55 0-1-.45-1-1s.45-1 1-1h9.79c-.21.34-.38.71-.47 1.11c-.86.02-1.67.34-2.32.89m-5 9c-2.76 0-5 2.24-5 5s2.24 5 5 5s5-2.24 5-5s-2.24-5-5-5m0 8.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5M19 12c-2.76 0-5 2.24-5 5s2.24 5 5 5s5-2.24 5-5s-2.24-5-5-5m0 8.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5M5.32 11H1c-.552 0-1-.45-1-1s.448-1 1-1h4.05c-.02.16-.05.33-.05.5c0 .53.12 1.04.32 1.5M6 7H2c-.55 0-1-.45-1-1s.45-1 1-1h5.97L6.09 6.87C6.05 6.91 6 6.96 6 7" />
                              </svg>
                              )}
                            </span>
                          ))
                        ) : (
                          <p>Not assigned any rider</p>
                        )}

                        {/* RiderDetails Modal */}
                        <RiderDetails show={show} setShow={setShow} data={selectedRider} />
                      </div>
                    </td>
                    <td>
                        {d?.orderStatus === "Cancelled" ? (
                          "-"
                        ) : (
                          <a
                            href="https://patient-in.creliohealth.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "#5E5E5E",
                            }}
                          >
                            Report
                            <img
                              className="downloadsvg"
                              src={download} // Ensure `download` is imported correctly
                              alt="download"
                              style={{ marginLeft: "5px" }} // Add some spacing between text and icon
                            />
                          </a>
                        )}
                      </td>
                      <td>
                     <a
                     href="javascript:void(0)"
                     style={{
                        display: "flex",
                       alignItems: "center",
                       cursor: "pointer",
                         textDecoration: "none",
                        color: "#5E5E5E",
                       }}
                       onClick={() => handleInvoice(d.orderReferenceId,d.testOrderedId
                      )}
                     >
                       Invoice
                       <img
                          className="downloadsvg"
                       src={download}
                       alt="download"
                    />
                    </a>
                    </td>
                    <td>
                    
                          {d?.orderStatus === "Cancelled" ? (
                            "-"
                          ) : (
                            // Check if there are riders assigned
                            d?.riders && d?.riders.length > 0 ? (
                              <button
                                onClick={() => handleSendRequest(d)}
                                style={{
                                  cursor: "pointer",
                                  padding: "5px 5px",
                                  backgroundColor: "#a71f23",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius:"5px",
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                Cancel Request
                              </button>
                            ) : (
                              <button
                                onClick={() => handleCancelRequestClick(d)}
                                style={{
                                  cursor: "pointer",
                                  padding: "5px 5px",
                                  backgroundColor: "#a71f23",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius:"5px",
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                Cancel
                              </button>
                            )
                          )}
                             
                            {showModal && (
                              <CancelRequestModal
                                show={showModal}
                                setShow={setShowModal}
                                onConfirm={handleConfirmCancelRequest}
                              />
                            )}
                         


                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          ) : loading ? (
            <div className="spin-wrapper">
              <Spinner />
            </div>
          ) : (
            <div className="no-data-found">No Data</div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default History;
