import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const CancelRequestModal = ({ show, setShow, onConfirm }) => {
  const [cancelReason, setCancelReason] = useState(""); // State to store the reason

  const handleClose = () => setShow(false);

  const handleConfirm = () => {
    if (!cancelReason.trim()) {
      alert("Please provide a reason for cancellation."); // Validation
      return;
    }
    onConfirm(cancelReason); // Pass the reason to the callback
    setShow(false); // Close the modal after confirming
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="cancelReason">
          <Form.Label>Reason for Cancellation</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your reason for cancellation..."
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Confirm Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelRequestModal;
