import React from "react";
import "./RiderDetails.css";
import Modal from "react-bootstrap/Modal";

const RiderDetails = ({ show, setShow, data }) => {
  console.log("RiderDetails data",data);
  
  if (!data) {
    return null; // If no data is available, don't render the modal
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      className="rider"
    >
      <Modal.Header closeButton>
        <Modal.Title>Rider Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data?.rider_date ? (
          <div>
            <p>
              <span className="m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <path
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-width="1.5"
                      d="M14 22h-4c-3.771 0-5.657 0-6.828-1.172S2 17.771 2 14v-2c0-3.771 0-5.657 1.172-6.828S6.229 4 10 4h4c3.771 0 5.657 0 6.828 1.172S22 8.229 22 12v2c0 3.771 0 5.657-1.172 6.828c-.653.654-1.528.943-2.828 1.07M7 4V2.5M17 4V2.5M21.5 9H10.75M2 9h3.875"
                    />
                    <path
                      fill="#000"
                      d="M18 17a1 1 0 1 1-2 0a1 1 0 0 1 2 0m0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0"
                    />
                  </g>
                </svg>
              </span>
              <strong>Appointment Number:</strong>{" "}
              {data?.appoimentNumber || "No Appointment Available"}
            </p>
            <p>
              <span className="m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 26 26"
                >
                  <path
                    fill="#000"
                    d="M16.563 15.9c-.159-.052-1.164-.505-.536-2.414h-.009c1.637-1.686 2.888-4.399 2.888-7.07c0-4.107-2.731-6.26-5.905-6.26c-3.176 0-5.892 2.152-5.892 6.26c0 2.682 1.244 5.406 2.891 7.088c.642 1.684-.506 2.309-.746 2.397c-3.324 1.202-7.224 3.393-7.224 5.556v.811c0 2.947 5.714 3.617 11.002 3.617c5.296 0 10.938-.67 10.938-3.617v-.811c0-2.228-3.919-4.402-7.407-5.557"
                  />
                </svg>
              </span>
              <strong>Rider Name:</strong>{" "}
              {data.rider_id?.name || "No Name Available"}
            </p>

            <p>
              <span className="m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#000"
                    d="M14.608 12.172c0 .84.239 1.175.864 1.175c1.393 0 2.28-1.775 2.28-4.727c0-4.512-3.288-6.672-7.393-6.672c-4.223 0-8.064 2.832-8.064 8.184c0 5.112 3.36 7.896 8.52 7.896c1.752 0 2.928-.192 4.727-.792l.386 1.607c-1.776.577-3.674.744-5.137.744c-6.768 0-10.393-3.72-10.393-9.456c0-5.784 4.201-9.72 9.985-9.72c6.024 0 9.215 3.6 9.215 8.016c0 3.744-1.175 6.6-4.871 6.6c-1.681 0-2.784-.672-2.928-2.161c-.432 1.656-1.584 2.161-3.145 2.161c-2.088 0-3.84-1.609-3.84-4.848c0-3.264 1.537-5.28 4.297-5.28c1.464 0 2.376.576 2.782 1.488l.697-1.272h2.016v7.057zm-2.951-3.168c0-1.319-.985-1.872-1.801-1.872c-.888 0-1.871.719-1.871 2.832c0 1.68.744 2.616 1.871 2.616c.792 0 1.801-.504 1.801-1.896z"
                  />
                </svg>
              </span>
              <strong>Rider Email:</strong>{" "}
              {data.rider_id?.email || "No Email Available"}
            </p>

            <p>
              <span className="m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M6 17c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6m9-9a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3M3 5v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2"
                  />
                </svg>
              </span>
              <strong>Rider Mobile:</strong>{" "}
              {data.rider_id?.mobile || "No Mobile Available"}
            </p>
            <p>
              <span className="m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="#000"
                    d="M32.25 6H29v2h3v22H4V8h3V6H3.75A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6"
                    class="clr-i-outline clr-i-outline-path-1"
                  />
                  <path
                    fill="#000"
                    d="M8 14h2v2H8z"
                    class="clr-i-outline clr-i-outline-path-2"
                  />
                  <path
                    fill="#000"
                    d="M14 14h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-3"
                  />
                  <path
                    fill="#000"
                    d="M20 14h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-4"
                  />
                  <path
                    fill="#000"
                    d="M26 14h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-5"
                  />
                  <path
                    fill="#000"
                    d="M8 19h2v2H8z"
                    class="clr-i-outline clr-i-outline-path-6"
                  />
                  <path
                    fill="#000"
                    d="M14 19h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-7"
                  />
                  <path
                    fill="#000"
                    d="M20 19h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-8"
                  />
                  <path
                    fill="#000"
                    d="M26 19h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-9"
                  />
                  <path
                    fill="#000"
                    d="M8 24h2v2H8z"
                    class="clr-i-outline clr-i-outline-path-10"
                  />
                  <path
                    fill="#000"
                    d="M14 24h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-11"
                  />
                  <path
                    fill="#000"
                    d="M20 24h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-12"
                  />
                  <path
                    fill="#000"
                    d="M26 24h2v2h-2z"
                    class="clr-i-outline clr-i-outline-path-13"
                  />
                  <path
                    fill="#000"
                    d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1"
                    class="clr-i-outline clr-i-outline-path-14"
                  />
                  <path
                    fill="#000"
                    d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1"
                    class="clr-i-outline clr-i-outline-path-15"
                  />
                  <path
                    fill="#000"
                    d="M13 6h10v2H13z"
                    class="clr-i-outline clr-i-outline-path-16"
                  />
                  <path fill="none" d="M0 0h36v36H0z" />
                </svg>
              </span>
              <strong>Rider Date:</strong> {data.rider_date}
            </p>
            <p>
              <span className="m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M15 3H9V1h6zm-2 16c0 1.03.26 2 .71 2.83c-.55.11-1.12.17-1.71.17a9 9 0 0 1 0-18c2.12 0 4.07.74 5.62 2l1.42-1.44c.51.44.96.9 1.41 1.41l-1.42 1.42A8.96 8.96 0 0 1 21 13v.35c-.64-.22-1.3-.35-2-.35c-3.31 0-6 2.69-6 6m0-12h-2v7h2zm4 9v6l5-3z"
                  />
                </svg>
              </span>
              <strong>Rider Start Time:</strong>{" "}
              {data.rider_start_time || "No Start Time Available"}
            </p>
            <p>
              <span className="m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M11 8h2v6h-2zm1 12c-3.87 0-7-3.13-7-7s3.13-7 7-7s7 3.13 7 7c.7 0 1.36.13 2 .35V13c0-2.12-.74-4.07-1.97-5.61l1.42-1.42c-.45-.51-.9-.97-1.41-1.41L17.62 6c-1.55-1.26-3.5-2-5.62-2a9 9 0 0 0 0 18c.59 0 1.16-.06 1.71-.17c-.31-.58-.53-1.23-.63-1.92c-.36.05-.71.09-1.08.09m3-19H9v2h6zm1.5 15.5v5h5v-5z"
                  />
                </svg>
              </span>
              <strong>Rider End Time:</strong>{" "}
              {data.rider_end_time || "No End Time Available"}
            </p>
          </div>
        ) : (
          <p>No Rider Data Available</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RiderDetails;
