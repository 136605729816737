import FILTER_DATA from "Global/Constants/Constants";
import "./Services.css";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import httpPathName from "Global/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import { setFilterModel } from "../../Redux/Actions/service.action";
import { setTestData } from "../../Redux/Actions/set.action";
import { useSearchParams } from "react-router-dom";

export default function FilterPanel(props) {
  const dispatch = useDispatch();
  const filterModel = useSelector((state) => state.setFilterState);

  const [searchParams] = useSearchParams();

  const condition = searchParams.get("condition");
  const organs = searchParams.get("organs");
  const gender = searchParams.get("gender");
  const search = searchParams.get("search");
  const category = searchParams.get("category");

  const resetFilter = {
    organs: [],
    condition: [],
    gender: [],
    // radiology_test: [],
    category: "",
    search: "",
  };

  useEffect(() => {
    const tempFilter = { ...filterModel };
    if (!!condition) {
      tempFilter.condition = [...filterModel.condition, condition];
    } else if (!!organs) {
      tempFilter.organs = [...filterModel.organs, organs];
    } else if (!!gender) {
      tempFilter.gender = [...filterModel.gender, gender];
    } else if (!!search) {
      tempFilter.search = search;
    }

    if (!!category) {
      tempFilter.category = category;
    }

    dispatch(setFilterModel(tempFilter));

    debounce(() => getData(tempFilter), 2000);
    return () => {
      dispatch(setFilterModel(null));
      dispatch(setTestData([]));
    };
  }, []);

  let debounceTimer;
  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  const token = localStorage.getItem("access_token");
  const getData = (filter) => {
    props.setLoading(true);
    httpPathName
      .post("test/getAllTests", filter, {
        headers: {
          "X-Auth-Token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        props.setLoading(false);
        if (Boolean(res.data.data)) {
          dispatch(setTestData(res.data.data));
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err);
        dispatch(setAlert("danger", err?.response?.data?.message));
      });
  };

  const onChangeFilter = (event, key, value) => {
    let filter = { ...filterModel };

    if (Boolean(event.target.checked)) {
      if (!filter[key]?.includes(value)) {
        let filterModelNew = {
          ...filter,
          [key]: [...filter[key], value],
        };
        dispatch(setFilterModel(filterModelNew));

        debounce(() => getData(filterModelNew), 200);
      }
    } else {
      let filterModelNew = {
        ...filter,
        [key]: [...filter[key]?.filter((el) => el !== value)],
      };
      dispatch(setFilterModel(filterModelNew));

      debounce(() => getData(filterModelNew), 200);
    }
  };

  const clearAllFilters = () => {
    dispatch(setFilterModel(resetFilter));
    debounce(() => getData(resetFilter), 200);
  };

  return (
    <div className="filterContainer">
      <div className="d-flex justify-content-between">
        <p className="topHeading mb-4">Filter by</p>
        <p className="clearAll mb-4" onClick={clearAllFilters}>
          Clear All
        </p>
      </div>
      <p className="subHeading mb-4">By Organs</p>
      <Form className="mb-3">
        {FILTER_DATA["organs"].map((e) => {
          return (
            <div className="flexRowAlign mb-3" key={e.key}>
              <div>
                <Form.Check
                  id={`checkbox-${e.key}`}
                  className="checkboxInput"
                  checked={
                    filterModel && filterModel["organs"]?.includes(e.title)
                  }
                  onChange={(event) => onChangeFilter(event, "organs", e.title)}
                />
              </div>
              <div className="filterIcon">{e.logo}</div>
              <span className="filterTitle">{e.title}</span>
            </div>
          );
        })}
      </Form>
      <p className="subHeading mb-4">Test by health condition</p>
      <Form className="mb-3">
        {FILTER_DATA["condition"].map((e) => {
          return (
            <div className="flexRowAlign mb-3" key={e.key}>
              <div>
                <Form.Check
                  id={`checkbox-${e.key}`}
                  className="checkboxInput"
                  checked={filterModel["condition"]?.includes(e.title)}
                  onChange={(event) =>
                    onChangeFilter(event, "condition", e.title)
                  }
                />
              </div>
              <div className="filterIcon">{e.logo}</div>
              <span className="filterTitle">{e.title}</span>
            </div>
          );
        })}
      </Form>
      {/* <p className="subHeading mb-4">Test by Gender</p>
      <Form className="mb-3">
        {FILTER_DATA["gender"].map((e) => {
          return (
            <div className="flexRowAlign mb-3" key={e.key}>
              <div>
                <Form.Check
                  id={`checkbox-${e.key}`}
                  className="checkboxInput"
                  checked={filterModel["gender"]?.includes(e.title)}
                  onChange={(event) => onChangeFilter(event, "gender", e.title)}
                />
              </div>
              <div className="filterIcon">{e.logo}</div>
              <span className="filterTitle">{e.title}</span>
            </div>
          );
        })}
      </Form> */}
      {/* <p className="subHeading mb-4">Test by Radiology</p>
      <Form className="mb-3">
        {FILTER_DATA["radiology_test"].map((e) => {
          return (
            <div className="flexRowAlign mb-3" key={e.key}>
              <div>
                <Form.Check
                  id={`checkbox-${e.key}`}
                  className="checkboxInput"
                  checked={filterModel["radiology_test"]?.includes(e.title)}
                  onChange={(event) =>
                    onChangeFilter(event, "radiology_test", e.title)
                  }
                />
              </div>
              <div className="filterIcon">{e.logo}</div>
              <span className="filterTitle">{e.title}</span>
            </div>
          );
        })}
      </Form> */}
    </div>
  );
}
