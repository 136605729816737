import React, { useEffect, useState } from "react";
import phoneIcon from "Assests/Icons/phoneIcon.svg";
import man from "Assests/Icons/man 3.svg";
import cupcake from "Assests/Icons/cupcake.svg";
import cut from "Assests/Icons/delete-7312752.svg";
import edit from "Assests/Icons/edit.svg";
import BookingUser from "Components/Cards/BookingUser/BookingUser";
import FamilyDetailCard from "Components/Cards/FamilyDetail/FamilyDetailCard";
import AddMember from "Components/Popup/AddMember/AddMember";
import EditMember from "Components/Popup/AddMember/EditMember";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import httpPathName from "Global/Config/Config";
import { setRelData } from "../../Redux/Actions/set.action";

const FamilyDetails = () => {
  const user = useSelector((state) => state?.setUserDataReducer);
  console.log("user",user);
  
  const relData = useSelector((state) => state?.setRelationData);
  // const [data,setData] = useState([])
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const [show, setShow] = useState(false);

  const getRelationshipData = async () => {
    const apiStatus = await httpPathName
      .get("users/relationships", { headers: { "x-auth-token": token } })
      .then((response) => {
        dispatch(setRelData(response?.data?.relationship));
        //setData(response?.data?.relationship)
        // dispatch(setAlert("success", "Order Data Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
      });
  };

  useEffect(() => {
    getRelationshipData();
  }, [show]);

  return (
    <>
      <div className="familyDetail">
        <p className="title">Family Details</p>
        <div className="familyDataWrapper">
          {relData?.length > 0 &&
            relData?.map((item, i) => (
              <div key={i}>
                {/* <BookingUser /> */}
                <FamilyDetailCard data={item} />
              </div>
            ))}
        </div>
          {!(user?.parentId&&user?.memberId)?
          <div>
            <button onClick={() => setShow(true)} className="addMember">
            + Add a New Member
          </button>
        <AddMember show={show} setShow={setShow} parentSetShow={setShow} />
        </div>:  
        ""
        }
      
        </div>
       
       
    </>
  );
};

export default FamilyDetails;
