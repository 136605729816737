import React, { useEffect } from "react";
import OldManCheckupRounded from "Assests/Images/OldManCheckupRounded.png";
import testFavicon from "Assests/Icons/testFavicon.png";
import search from "Assests/Images/search-7269748.png";
import plus from "Assests/Icons/plus-small-7333544.png";
import userWhite from "Assests/Icons/userWhite.png";
import "./SelectedTest.css";
import { useState } from "react";
import { bookNowQtyApi, deleteTestApi } from "API/post.api";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { fetchTheCartApi } from "API/get.api";
import {
  setCartDataAction,
  setSelectCartMemberId,
} from "../../../Redux/Actions/set.action";
import httpPathName from "Global/Config/Config";
import { useLocation } from "react-router-dom";
import { bookNowApi } from "API/post.api";
import AddMember from "Components/Popup/AddMember/AddMember";

const SelectedTest = ({ data }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [relationData, setRelationData] = useState([]);
  const [selectShow, setSelectShow] = useState(false);
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("access_token");

  const memberList = useSelector((state) => state.setMemberListData);
  const selectedId = useSelector((state) => state?.setCartSelectedMemberId);
  const user = useSelector((state) => state?.setUserDataReducer);
  console.log("user",user);

  const {
    test_name,
    description,
    quantity,
    mrp,
    testId,
    _id,
    relativeId,
    cartId,
  } = data ? data : "";

  const [btnDisable, setBtnDisable] = useState(false);

  const getRelationshipData = async () => {
    const apiStatus = await httpPathName
      .get(`users/relationships?name=${search}`, {
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        if(user?.parentId&&user?.memberId){
          const memberData=response?.data?.relationship;
          const memberFilter = memberData.filter(f=>f._id===user?.memberId)
          setRelationData(memberFilter);
        }else{
          setRelationData(response?.data?.relationship);
        }
        
        // setRelationData(response?.data?.relationship);
        // dispatch(setAlert("success", "Order Data Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
      });
  };

  useEffect(() => {
    getRelationshipData();
  }, [search, show]);

  const handleChange = async (e) => {
    if (!e) {
      dispatch(setAlert("error", "Please Select Member"));
      return;
    }

    const formData = {
      relativeId: e,
    };

    try {
      // Update the cart by adding the patient
      const response = await httpPathName.put(
        `cart/${_id}/addpatient`,
        formData,
        { headers: { "x-auth-token": token } }
      );
      if ([200, 201].includes(response?.status)) {
        // Fetch relationship data
        getRelationshipData();

        // Fetch the updated cart data
        const internalApiResponse = await fetchTheCartApi(token);

        // Dispatch the updated cart data
        dispatch(setCartDataAction({ tests: internalApiResponse?.data?.data }));

        // Dispatch success alert
        dispatch(setAlert("success", "Member Updated Successfully"));
      } else {
        dispatch(setAlert("error", response?.response?.data?.message));
      }
    } catch (err) {
      console.error(err);
      // Dispatch error alert with the error message
      dispatch(setAlert("error", err?.response?.data?.message));
    }
  };

  return (
    <>
      <div className="selected-test">
        <img src={testId?.image || testFavicon} alt="Test" />
        <div>
          {/* <h6 className="card_title">{test_name || "No Test Name"}</h6> */}
          <h7 className="card_title_name">{test_name || "No Test Name"}</h7>
          {/* <p>{description || "No Description"}</p> */}
          <div className="selected-test-wrapper">
            
            <div>
              {location?.pathname == "/cart" && (
                <button
                  style={{ background: "#f99d1c", color: "white" }}
                  onClick={() => {
                    const apiStatus = bookNowQtyApi(
                      token,
                      testId?._id,
                      "increment"
                    );
                    apiStatus
                      .then((response) => {
                        console.log(response);
                        // navigate("/cart");
                        const internalApi = fetchTheCartApi(token);
                        internalApi
                          .then((response) => {
                            dispatch(
                              setCartDataAction({ tests: response?.data?.data })
                            );
                          })
                          .catch((err) => {
                            console.log(err);
                            dispatch(
                              setAlert("error", err?.response?.data?.message)
                            );
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                        dispatch(setAlert("error", err?.response?.data?.error));
                      });
                  }}
                >
                  +
                </button>
              )}

              {location?.pathname !== "/cart" && relativeId?.fullName && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      background: "#3AA1ED",
                      borderRadius: "100%",
                      padding: "2%",
                    }}
                  >
                    <img
                      style={{
                        width: "20px",
                        height: "18px",
                        objectFit: "contain",
                      }}
                      src={userWhite}
                      alt="white"
                    />
                  </div>

                  <span style={{ whiteSpace: "nowrap", marginLeft: "4px" }}>
                    {relativeId?.fullName
                      ? relativeId?.fullName + ` (${relativeId.relationship})`
                      : ""}
                  </span>
                </div>
              )}
            </div>
            <p>₱{mrp || "0"}</p>
          </div>
        </div>
        {location?.pathname !== "/collect-location" && (
         
          <div className="select_Search">
            {selectShow ? (
              <div
                onMouseLeave={() => setSelectShow(false)}
                className="inputSearchWrapper"
              >
                <div className="inputWrapper">
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21.5305 20.47L16.8405 15.78C18.1991 14.1486 18.8765 12.0561 18.7318 9.938C18.587 7.81985 17.6313 5.83905 16.0633 4.40762C14.4953 2.97619 12.4359 2.20434 10.3134 2.25262C8.19082 2.30091 6.1686 3.16561 4.66735 4.66686C3.1661 6.16811 2.3014 8.19033 2.25311 10.3129C2.20483 12.4354 2.97668 14.4949 4.40811 16.0628C5.83954 17.6308 7.82034 18.5865 9.93849 18.7313C12.0566 18.876 14.1491 18.1986 15.7805 16.84L20.4705 21.53C20.6111 21.6704 20.8017 21.7493 21.0005 21.7493C21.1992 21.7493 21.3899 21.6704 21.5305 21.53C21.6709 21.3894 21.7498 21.1987 21.7498 21C21.7498 20.8012 21.6709 20.6106 21.5305 20.47ZM3.75048 10.5C3.75048 9.16497 4.14636 7.85993 4.88806 6.7499C5.62976 5.63986 6.68397 4.7747 7.91737 4.26381C9.15077 3.75292 10.508 3.61925 11.8173 3.8797C13.1267 4.14015 14.3295 4.78302 15.2735 5.72703C16.2175 6.67103 16.8603 7.87376 17.1208 9.18314C17.3812 10.4925 17.2476 11.8497 16.7367 13.0831C16.2258 14.3165 15.3606 15.3707 14.2506 16.1124C13.1406 16.8541 11.8355 17.25 10.5005 17.25C8.71109 17.2474 6.99573 16.5353 5.73044 15.27C4.46514 14.0047 3.75313 12.2894 3.75048 10.5Z"
                      fill="black"
                    />
                  </svg>
                </div>
                {relationData?.length > 0 &&
                  relationData.map((item, i) => (
                    <div
                      onClick={() => {
                        handleChange(item._id);
                        setSelectShow(false);
                      }}
                      className="memeberName"
                      key={i}
                      value={item._id}
                    >
                      {`${item?.fullName} (${item?.relationship})`}
                    </div>
                  ))}
                <button onClick={() => setShow(true)}>
                  <span style={{ fontSize: "14px" }}>Add New Member</span>
                  <div>
                    <img src={plus} alt="plus" />
                  </div>
                </button>
              </div>
            ) : (
              <div
                onClick={(e) => setSelectShow(!selectShow)}
                className="inputSearchWrapper"
              >
                <select>
                  <option hidden>
                    {relativeId?.fullName
                      ? relativeId?.fullName + ` (${relativeId.relationship})`
                      : "Select Family members"}
                  </option>
                </select>
              </div>
            )}
          </div>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          onClick={(e) => {
            e.stopPropagation();
            const apiStatus = deleteTestApi(token, _id, "deletion");
            apiStatus
              .then((response) => {
                dispatch(setAlert("success", response?.data?.message));

                const internalApi = fetchTheCartApi(token);
                internalApi
                  .then((response) => {
                    dispatch(
                      setCartDataAction({ tests: response?.data?.data })
                    );
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(setAlert("error", err?.response?.data?.message));
                  });
              })
              .catch((err) => {
                console.log(err);
                dispatch(setAlert("error", err?.response?.data?.message));
                setBtnDisable(false);
              });
          }}
        >
          <path
            d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96452 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7474 9.41495 20.7193 6.93654 18.8914 5.10863C17.0635 3.28073 14.5851 2.25265 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2474 14.1872 19.3773 16.2841 17.8307 17.8307C16.2841 19.3773 14.1872 20.2474 12 20.25Z"
            fill="#5E5E5E"
          />
          <path
            d="M16.5293 7.47C16.3887 7.32955 16.1981 7.25066 15.9993 7.25066C15.8006 7.25066 15.61 7.32955 15.4693 7.47L11.9993 10.94L8.52934 7.47C8.38716 7.33752 8.19912 7.26539 8.00482 7.26882C7.81052 7.27225 7.62513 7.35096 7.48772 7.48837C7.35031 7.62579 7.27159 7.81117 7.26817 8.00547C7.26474 8.19978 7.33686 8.38782 7.46934 8.53L10.9393 12L7.46934 15.47C7.32889 15.6106 7.25 15.8012 7.25 16C7.25 16.1987 7.32889 16.3894 7.46934 16.53C7.60997 16.6704 7.80059 16.7493 7.99934 16.7493C8.19809 16.7493 8.38871 16.6704 8.52934 16.53L11.9993 13.06L15.4693 16.53C15.61 16.6704 15.8006 16.7493 15.9993 16.7493C16.1981 16.7493 16.3887 16.6704 16.5293 16.53C16.6698 16.3894 16.7487 16.1987 16.7487 16C16.7487 15.8012 16.6698 15.6106 16.5293 15.47L13.0593 12L16.5293 8.53C16.6698 8.38937 16.7487 8.19875 16.7487 8C16.7487 7.80125 16.6698 7.61062 16.5293 7.47Z"
            fill="#5E5E5E"
          />
        </svg>
      </div>
      <AddMember show={show} setShow={setShow} parentSetShow={setShow} />
    </>
  );
};

export default SelectedTest;
