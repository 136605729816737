// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop {
    background-color: transparent !important;
  }
  
  .modal-dialog {
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Popup/RiderDetails/RiderDetails.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;EAC1C;;EAEA;IACE,WAAW;EACb","sourcesContent":[".modal-backdrop {\n    background-color: transparent !important;\n  }\n  \n  .modal-dialog {\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
