import React, { useState, useRef, useEffect } from "react";
import "./Wellness.css";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import httpPathName from "Global/Config/Config";
import { ReactComponent as CartIcon } from "Assests/Icons/cartIcon.svg";
import { bookNowApi } from "API/post.api";
import {
  setAuthenticationModel,
  setContactModel,
} from "../../Redux/Actions/model";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import diagnostics from "Assests/Images/diagnostics.png";
import Spinner from "react-bootstrap/Spinner";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import partnerWithUs from "Assests/Images/partnerWithUs.png";
import Wanner from "Components/Wnner/Wanner";
import WellnessSlider from "Components/Slider/WellnessSlider/WellnessSlider";
import ContactUs from "Components/Popup/ContactUs/ContactUs";
// import diagnostic from "./path/to/diagnostic-image.jpg";
import WellnessRpt from "Assests/Images/wellnessrpt.jpg";
import WellnesPln from "Assests/Images/wellnesplns.jpg";
import activitytrack from  "Assests/Images/activitytracking.jpg";
import nutritionhyd from "Assests/Images/nutritionhydration.jpg";
import helthmet from "Assests/Images/healthmetrics.jpg";
import mntlhelth from  "Assests/Images/mentalhealth.jpg";
import interactive from "Assests/Images/interactivewellness.jpg";
import rewardsrec from "Assests/Images/rewardsrecognition.jpg";
import Labhome from "Assests/Images/Labhome.jpg";
import onlinedoctor from "Assests/Images/onlinedoctorconsultation.jpg";
import wellnessasses from "Assests/Images/wellnessassesment.jpg";
import dimension from "Assests/Images/wellness-dimensions.png";
import MicrosoftTeams from "Assests/Images/MicrosoftTeams-image.png";
import Resources from "Assests/Images/Labhome.jpg";
const nav = [
  "Annual Physical Examination",
  "Pre-Employment Checkup",
  "Preventive Health Packages",
  // "OncoNed",
];

const Wellness = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [expression, setExpression] = useState(generateRandomKeyword());
  const [captcha, setCaptcha] = useState("");
  const formRef = useRef(null);
  const canvasRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const token = localStorage.getItem("access_token");
  const location = useLocation();

  const scrollToSection = () => {
    const targetSection = document.getElementById("contactSection");

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Our corporate healthcare solutions elevate healthcare standards","Explore our corporate solutions designed for optimized healthcare of your employees. Collaborate with us to boost healthcare standards within your organization.")

  const fetchEmployData = async () => {
    setLoading(true);

    const apiStatus = await httpPathName
      .get("home/getEmploymentCheckTestList", {
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        // dispatch(setAlert("success", response?.data?.message));
        setData(response?.data?.data);
        setLoading(false);
        //setShow(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEmployData();
  }, []);


  function generateRandomKeyword() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let keyword = "";

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      keyword += characters.charAt(randomIndex);
      // setExpression(keyword);
    }

    return keyword;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Service offerings data
  const services = [
    {
      title: "Online Doctor Consultation",
      description: "Access to doctors from anywhere.",
    },
    {
      title: "Lab@Home",
      description: "Convenient and hassle-free sample collection at your place.",
    },
    {
      title: "Physical Wellness",
      description: "Online Diet Counselling, Fitness tracker.",
    },
    {
      title: "Mental Wellness",
      description: "Mental Health Expert counselling and stress management.",
    },
    {
      title: "Individual Health Screening",
      description: "Comprehensive health screenings and reports.",
    },
    {
      title: "Corporate Wellness Packages",
      description: "Annual Physical Examination, Pre-Employment Checkup.",
    },
  ];
  const handleClick = (heading) => {
    console.log("Clicked on:", heading);
    // Add more logic here if you need to handle clicks, like opening a modal or navigating to another page
  };
  
  const valueItems = [
    "Promote Wellness Awareness",
    "Encourage Healthy Habits",
    "Foster Community Support",
    "Measure Impact",
    "Adaptable Solutions",
    "Reduce Healthcare Costs",
    "Boost Performance",
    "Positive Environment"
  ];
   
  
  const featureData = [
    {
      heading: "Wellness Assessments & Reports",
      description:
        "Start your wellness journey with a comprehensive assessment that helps you understand your current health status. Our detailed reports provide insights and recommendations, empowering you to take informed actions toward a healthier life.",
        image: WellnessRpt,
    },
    {
      heading: "Personalized Wellness Plans",
      description:
        "Receive a tailored wellness plan based on your unique health profile and goals. From nutrition to fitness, our experts will craft a plan that fits your lifestyle, helping you make sustainable changes and reach your wellness milestones.",
        image: WellnesPln,
    },
    {
      heading: "Activity Tracking",
      description:
        "Stay active and motivated with our easy-to-use activity tracking feature. Monitor your steps and set fitness goals to stay on track toward a more active lifestyle.",
      image:activitytrack,
      },

    {
      heading: "Nutrition & Hydration",
      description:
        "Balance your diet and hydration with our platform that helps you track what you eat and your water intake, providing personalized tips on how to improve your nutrition and maintain healthy hydration levels.",
      image:nutritionhyd,
    },
    // Additional sections can be added here
    {
      heading: "Health Metrics",
      description:
      "Monitor essential health metrics such as blood pressure, weight, BMI and more. Our platform ensures that you can track your progress over time and make data-driven decisions for better health outcomes.",
      image:helthmet,
    },
    {
      heading: "Mental Health",
      description:
        "Your mental wellness is a priority. Access counselling sessions with mental health experts, participate in stress-relief activities, and use guided meditation and mindfulness practices to maintain emotional balance and reduce stress.",
      image:mntlhelth,
      },
    {
      heading: "Interactive Wellness Challenges for Corporates",
      description:
        "Make wellness fun and engaging! Participate in interactive wellness challenges, compete with colleagues to reach new milestones together. These challenges will keep you motivated as you work toward your health goals.",
      image:interactive,
    },
    {
      heading: "Rewards & Recognition for Corporates",
      description:
        "Earn rewards for staying committed to your health journey. Our platform offers a recognition system where you can earn points for reaching goals, participating in challenges, and maintaining healthy habits. Redeem these points for exciting incentives.",
      image:rewardsrec,
      },
    {
      heading: "Lab@Home",
      description:
        "Our platform offers hassle-free alternatives to traditional lab visits by providing convenient sample collection services at your doorste. With trained professionals handling logistics and ensuring quality care, you can prioritize their health without compromising convenience.",
      image:Labhome,
      },
    {
      heading: "Online Doctor Consultation",
      description:
        "Get expert medical advice without the need for an in-person visit. Our platform allows you to consult with doctors and specialists at your convenience, ensuring you receive the care you need wherever you are leveraging technology.",
      image:onlinedoctor,
      },
  ];

  const resourcesData = [
    
    {
      heading: "Resources",
      description:
        "Sed nec eros at augue tincidunt malesuada. Praesent id est sem. Donec eget nisl sit amet erat pretium congue et vel dolor. Proin purus arcu, tempus et nunc eu, pretium accumsan diam. Donec placerat odio ut luctus semper. Duis tincidunt, sem quis efficitur sodales, augue arcu posuere tortor, sed maximus justo odio sit amet dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image:Resources,
      },    
  ];

  
  function section1() {
    return (
      <>
        <div className="section1-warpper">
          <h5>
            Welcome To <span className="bold-text" style={{ paddingLeft: "10px" }}>Digital Wellness</span>
          </h5>
          <p>
            Wellness is a journey, and we’re here to support you at every step of the way. Start today and unlock exclusive services, personalized tips, and resources to help you build healthy habits and routines. Whether it’s for your body, mind, or overall well-being, our platform gives you the tools you need to thrive.
          </p>
        </div>
  
        <div className="section1-warpper">
         
          <div className="wellness-card left-content">
            <img src={dimension} alt="Feature 1" className="wellness-image" />
            <div className="wellness-content">
            <h5>
            Wellness <span className="bold-text" style={{ paddingLeft: "10px" }}>Dimensions</span>
          </h5>
              <p>
                Wellness isn’t just about one area of life, it’s a journey that touches on many aspects. Our program focuses on six key dimensions:
              </p>
              <p><b>Physical:</b> Stay active and maintain a healthy lifestyle.</p>
              <p><b>Mental:</b> Build resilience and manage stress effectively.</p>
              <p><b>Socioemotional:</b> Foster strong relationships and emotional balance.</p>
              <p><b>Intellectual:</b> Keep your mind sharp with continuous learning.</p>
              <p><b>Spiritual:</b> Find purpose and inner peace.</p>
              <p>These dimensions work together to create a holistic approach to wellness, helping you thrive in all areas of life. Explore resources to help you grow in every area.</p>
            </div>
          </div>
        </div>
  
        <div className="section1-warpper">
          
          <div className="wellness-card right-content">
            <div className="wellness-content">
            <h5>
            Wellness <span className="bold-text" style={{ paddingLeft: "10px" }}>Assessment</span>
          </h5>
          <h4>Start your Wellness Journey Today!</h4>
              <p>Take the first step toward better well-being by identifying your strengths and growth areas across five dimensions of wellness.</p>
              <p>Our quick and easy 10-minute assessment evaluates your habits, stress levels, and lifestyle preferences. With these insights, we create personalized wellness plans that align with your goals, ensuring maximum impact and engagement.</p>
            </div>
            <img src={wellnessasses} alt="Feature 2" className="wellness-image" />
          </div>
        </div>
      </>
    );
  }
  
  
  
  function section2() {
    return (
      <>
        <div className="section2control">
          <div className="row">
            <div className="col col-md-6">
              <div className="content">
                <div className="d-flex">
                <h5>
                  Our 
                  <span className="bold-text" style={{ paddingLeft: "10px" }}>
                  Offering
                  </span>
                </h5>
                </div>
                
                <button >
                {/* onClick={() => navigate("/services/all-test-package")} */}
                  <span className="d-inline-block me-3 fw-semibold text-decoration-underline">
                    View More
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72"
                    height="12"
                    viewBox="0 0 72 12"
                    fill="none"
                  >
                    <path
                      d="M71.5303 6.53033C71.8232 6.23744 71.8232 5.76256 71.5303 5.46967L66.7574 0.696699C66.4645 0.403806 65.9896 0.403806 65.6967 0.696699C65.4038 0.989593 65.4038 1.46447 65.6967 1.75736L69.9393 6L65.6967 10.2426C65.4038 10.5355 65.4038 11.0104 65.6967 11.3033C65.9896 11.5962 66.4645 11.5962 66.7574 11.3033L71.5303 6.53033ZM0 6.75H71V5.25H0V6.75Z"
                      fill="#A71F23"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="col col-md-6">
              <div className="slider">
                <WellnessSlider />
              </div>
            </div>
            {/* <div className="col col-md-2"></div> */}
          </div>
        </div>
      </>
    );
  }

  // function section3() {
  //   return (

  //     <div className="section3-warpper">
  //       <h1>
  //         How can we  <span className="bold-text" style={{ paddingLeft: "10px" }}>
  //                   Add value
  //                 </span>
  //       </h1>
  //       <p>
  //         <ul>
  //           <li>Promote Wellness Awareness</li>
  //           <li>Encourage Healthy Habits</li>
  //           <li>Foster Community Support</li>
  //           <li>Measure Impact</li>
  //           <li>Adaptable Solutions</li>
  //           <li>Reduce Healthcare Costs</li>
  //           <li>Boost Performance</li>
  //           <li>Positive Environment</li>
  //         </ul>
  //       </p>

  //     </div>

  //   );
  // }
  const section3 = () => {
    return (
      <>
      <div className="section3-warpper">
        <h5>
          How can we <span className="bold-text" style={{ paddingLeft: "10px" }}>Add value</span>
        </h5>
        <div className="card-container">
          {valueItems.map((item, index) => (
            <div key={index} className="value-card">
              <p>{item}</p>
            </div>
          ))}
        </div>
      </div>
      </>
    );
    
}

  //   );
  // }
  const section4 = () => {
    return (
      <div className="section4-wrapper">
        <h1> <span className="bold-text">Features</span></h1>
        <p padd>Our Wellness Platform is your Complete Health & Wellness Hub</p>
        <p>
          Our platform is packed with tools designed to help you achieve your health goals and maintain long-term wellness. Each feature is carefully crafted to support every aspect of your physical and mental well-being.
        </p>
  
        {featureData.map((feature, i) => (
          <div className={`feature-card ${i % 2 === 0 ? "left-image" : "right-image"}`} key={i}>
            {/* Content comes first (either on left or right based on index) */}
            <div className="feature-content">
              <h3>{feature.heading}</h3>
              <p>{feature.description}</p>
            </div>
  
            {/* Image comes second (either on right or left based on index) */}
            {feature.image && (
              <img src={feature.image} alt={feature.heading} className="feature-image" />
            )}
          </div>
        ))}
      </div>
    );
  };

  
  const section5 = () => {
    return (
        <div className="section-5">
          {resourcesData.map((feature, i) => (
            <div className={`feature-card ${i % 2 === 0 ? "left-image" : "right-image"}`} key={i}>
              {/* Content comes first (either on left or right based on index) */}
              <div className="feature-content">
                <h3>{feature.heading}</h3>
                <p>{feature.description}</p>
              </div>
    
              {/* Image comes second (either on right or left based on index) */}
              {feature.image && (
                <img src={feature.image} alt={feature.heading} className="feature-image" />
              )}
            </div>
          ))}
        </div>
    );    
  }

  const section6 = () => {
    return (
      <div id="contactSection" className="section-10">
        <h3> 
            <span className="bold-text">Contact Us for    <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (location.pathname == "/") {
                  scrollToSection();
                } else {
                  dispatch(setContactModel(true));
                }
              }}
            >
              Pricing
            </a></span>
          </h3>
      </div>
    );
  };


  return (
    <>
      <MetaDetails
        title="Our corporate healthcare solutions elevate healthcare standards"
        description="Explore our corporate solutions designed for optimized healthcare of your employees. Collaborate with us to boost healthcare standards within your organization."
      />

      <Header />
      <div className="corporate">
        <Wanner title="Wellness" image={partnerWithUs} dot={false} />

        {/* <div className="partnerTitlemiddle">
          <h1>Corporate</h1>
          <img src={dot3} alt="dot3" />


        </div> */}
        <div className="section1">{section1()}</div>
        <div className="section2 unique-section">{section2()}</div>
        <div className="section3">{section3()}</div>
        <div className="section4">{section4()}</div>
        <div className="section5">{section5()}</div>
        <div className="section5">{section6()}</div>
        {/*<div id="contactSection" className="section-10">
        <h1> <span className="bold-text">Pricing</span></h1>
          <div className="contactImageWrapper">
            <img src={MicrosoftTeams} alt="doctor" className="img-fluid" />
          </div>
          <ContactUs />
        </div>*/}

      </div>
      <Footer />
    </>
  );
};

export default Wellness;
