import React, { useEffect, useState } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import workinglab from "Assests/Images/woman-working-lab-with-microscope.png";
import vision from "Assests/Images/businessman-hand-holding-lightbulb-with-glowing-light-creative-smart-thinking-inspiration-innovation-with-network-concept 1.png";
import mission from "Assests/Images/people-using-digital-device-while-meeting 1.png";
import icon1 from "Assests/Images/icon1.svg";
import Mentalhealth from "Assests/Images/mentalhealth.jpg";

import httpPathName from "Global/Config/Config";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";

const About = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAbout = async () => {
    const apiStatus = await httpPathName
      .get(`admin/content/all-aboutus`)
      .then((response) => {
        console.log("respoinse", response);
        setData(response?.data?.Allcontent[0]);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetched Error"));
      });
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <><Header />
    <div className="about1">
      <div className="section5">
        <div className="content">
          <div className="content-left">
            <h3>Mental Wellness</h3>
            {/* <img className="wanner3dot" src={dot3} alt="dot3" /> */}
            
            <p>Mental health is just as important as physical health, and we are here to support your emotional well-being with expert care and guidance.</p>
          </div>

          <div className="img-container right">
            <img
              className=""
              src={Mentalhealth}
              alt="Mentalhealth" />
          </div>
        </div>
      </div>

      
      <div className="section4-wrapper">
      <h3>Mental Health Expert Counselling</h3>
      <p>Speak with certified mental health professionals who provide compassionate counselling to help you navigate stress, anxiety, and emotional challenges. Our confidential sessions are designed to create a safe space for you to explore and improve your mental health.</p>

      </div>

      <div className="section4-wrapper">
      <h3>Stress Management Sessions</h3>
      <p>In today's fast-paced world, managing stress is crucial. Our specialized stress management sessions are tailored to equip you with effective techniques and coping strategies that reduce stress, enhance resilience, and improve your overall well-being.</p>

      </div>
     




    </div>
    <Footer />
    </>
    
  );
};

export default About;
