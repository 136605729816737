import React, { useState, useEffect } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import "./Profile.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import User from "Assests/Icons/userIcon.svg";
import history from "Assests/Icons/history.svg";
import familyDetail from "Assests/Icons/familyDetail.svg";
import setting from "Assests/Icons/setting.svg";
import phoneIcon from "Assests/Icons/phoneIcon.svg";
import location from "Assests/Icons/location-pin.svg";
import pooja from "Assests/Images/pooja.svg";
import profile from "Assests/Images/profile.png";
import cupcake from "Assests/Icons/cupcake.svg";
import edit from "Assests/Icons/edit.svg";
import MyProfile from "./MyProfile";
import History from "./History";
import FamilyDetails from "./FamilyDetails";
import Settings from "./Settings";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import MyProfilePhone from "./MyProfilePhone";
import HistoryPhone from "./HistoryPhone";
import SettingPhone from "./SettingPhone";
import { useSelector, useDispatch } from "react-redux";
import { setProfileActiveTab } from "../../Redux/Actions/profile";
import moment from "moment";
import httpPathName from "Global/Config/Config";
import { setAlert } from "../../Redux/Actions/alert.action";

const Profile = () => {
  const [orderData, setOrderData] = useState([]);
  const [profileimg,setProfile]=useState(profile)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const activeTab = useSelector((state) => state?.setProfileTab);
  const user = useSelector((state) => state?.setUserDataReducer);
  console.log("user",user)

  const today = moment();
  const birthMoment = moment(user?.dob, "DD-MM-YYYY");
  // const years = today.diff(birthMoment, "years");

  const getOrder = async () => {
    const apiStatus = await httpPathName
      .get("users/get-orders", { headers: { "x-auth-token": token } })
      .then((response) => {
        setOrderData(response?.data?.order);
        console.log("response",response)
        // dispatch(setAlert("success", "Order Data Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
      });
  };

  // const getuserdetail = async () => {
  //   try {
  //     const response = await httpPathName.get(
  //       "users/update-profile", 
        
  //       { headers: { "x-auth-token": token } }
  //     );
      
  //     setProfile(response?.data);
  //     console.log("response", response);
  //   } catch (err) {
  //     console.log(err);
  //     dispatch(setAlert("error", err?.response?.data?.message));
  //   }
  // };
  

  useEffect(() => {
    getOrder();
    // getuserdetail();
  }, []);

  return (
    <>
      <Header />
      <div className="profileWrapper">
        <div className="homeProfile">
          <span
            onClick={() => navigate("/")}
            style={{ cursor: "pointer", color: "black" }}
          >
            Home
          </span>
          /<span>Profile</span>
        </div>
        <div className="profileBodyWrapper">
          <div className="leftSection">
            <div className="edit">
              <img onClick={() => navigate("/profile/edit")} src={edit} />
            </div>

            <img
              style={{ borderRadius: "50%" }}
              className="profilepic"
              src={user?.profileimg || profile}
              alt="profile"
            />
            <h1 className="name">{user?.name}</h1>
            <p className="email">{user?.email}</p>
            <hr />
            <div className="personalContentWraper">
              <h1>Personal Information</h1>
              {user?.name && (
                <div className="personalContent">
                  <img src={User} alt="user" />
                  <p>{user?.name}</p>
                </div>
              )}
              {user?.mobile && (
                <div className="personalContent">
                  <img src={phoneIcon} alt="phoneIcon" />
                  <p>{user?.mobile}</p>
                </div>
              )}

              {(user?.location || user?.state) && (
                <div className="personalContent">
                  <img src={location} alt="user" />
                  <p>{user?.location || user?.state}</p>
                </div>
              )}

              {user?.dob && (
                <div className="personalContent">
                  <img src={cupcake} alt="user" />
                  <p>
                    {user?.dob} | Age:{" "}
                    <span style={{ fontWeight: "500" }}>{user?.age}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="rightSection">
            <Tabs
              defaultActiveKey={activeTab}
              id="fill-tab-example"
              className="mb-3 tabsSection"
              fill
              variant="underline"
            >
              <Tab
                eventKey="profile"
                title={
                  <div
                    onClick={() => {
                      dispatch(setProfileActiveTab("profile"));
                    }}
                    className="profileTab"
                  >
                    <img src={User} />
                    My Profile
                  </div>
                }
              >
                <MyProfile orderData={orderData} />
              </Tab>
              <Tab
                eventKey="history"
                title={
                  <div
                    onClick={() => dispatch(setProfileActiveTab("history"))}
                    className="profileTab"
                  >
                    <img src={history} />
                    History
                  </div>
                }
              >
                <History />
              </Tab>
              <Tab
                eventKey="family"
                title={
                  <div
                    onClick={() => dispatch(setProfileActiveTab("family"))}
                    className="profileTab"
                  >
                    <img src={familyDetail} />
                    Family Details
                  </div>
                }
              >
                <FamilyDetails />
              </Tab>
              <Tab
                eventKey="setting"
                title={
                  <div
                    onClick={() => dispatch(setProfileActiveTab("setting"))}
                    className="profileTab"
                  >
                    <img src={setting} />
                    Settings
                  </div>
                }
              >
                <Settings />
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="profileBodyPhoneWrapper">
          <div className="topRow">
            <img
              onClick={() => navigate("/profile/edit")}
              className="edit"
              src={edit}
            />
            <img
              className="profilepic"
              style={{ borderRadius: "100%" }}
              src={profileimg}
              alt="profile"
            />
            <h1 className="name">{user?.name}</h1>
            <p className="email">{user?.email}</p>
            <hr />
            <div className="personalContentWraper">
              <h1>Personal Information67890</h1>
              <div className="personalContent">
                <img src={User} alt="user" />
                <p>Female</p>
              </div>
              <div className="personalContent">
                <img src={phoneIcon} alt="phoneIcon" />
                <p>+91 9566636254</p>
              </div>
              <div className="personalContent">
                <img src={location} alt="user" />
                <p>Philliphins, PhiSanté</p>
              </div>
              <div className="personalContent">
                <img src={cupcake} alt="user" />
                <p>28.10.1890 | Age: 25</p>
              </div>
            </div>
          </div>
          <div className="accord">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {
                    <div className="profileTab">
                      <img src={User} />
                      My Profile
                    </div>
                  }
                </Accordion.Header>
                <Accordion.Body>
                  <MyProfile orderData={orderData} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {
                    <div className="profileTab">
                      <img src={history} />
                      History
                    </div>
                  }
                </Accordion.Header>
                <Accordion.Body>
                  {/* <HistoryPhone /> */}
                  <History />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {
                    <div className="profileTab">
                      <img src={familyDetail} />
                      Family Details
                    </div>
                  }
                </Accordion.Header>
                <Accordion.Body>
                  <FamilyDetails />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  {
                    <div className="profileTab">
                      <img src={setting} />
                      Settings
                    </div>
                  }
                </Accordion.Header>
                <Accordion.Body>
                  <Settings />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
