import React, { useRef, useState, useEffect } from "react";
import partnerWithUs from "Assests/Images/partnerWithUs.png";
import dot3 from "Assests/Images/dot3.svg";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import "./Partners.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import httpPathName from "Global/Config/Config";
import { useNavigate } from "react-router-dom";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import Wanner from "Components/Wnner/Wanner";

const Partners = () => {
  const formRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [expression, setExpression] = useState(generateRandomKeyword());
  const [captcha, setCaptcha] = useState("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");  // For filtering
  const [selectedCountryName, setSelectedCountryName] = useState("");  // For saving
  const [selectedStateId, setSelectedStateId] = useState("");  // For filtering
  const [selectedStateName, setSelectedStateName] = useState("");  // For saving



   // Fetch countries on component mount
   const fetchCountries = async () => {
    try {
      const res = await httpPathName.get(`location/getcountries`);
      setCountries(res?.data?.data);
      console.log("setCountries",res);
      
    } catch (e) {
      console.error("Error fetching countries", e);
    }
  };

  // Fetch states based on selected country
  const fetchStates = async (countryId) => {
    try {
      const res = await httpPathName.get(`location/getstate/${countryId}`);
      // setStates([]);
      setStates(res?.data?.data);
      console.log("setStates",res);
        //  / Clear previous state and cities selections
        setSelectedStateId(""); 
        setSelectedStateName(""); 
        setCities([]); 
    } catch (e) {
      console.error("Error fetching states", e);
    }
  };

  // Fetch cities based on selected state
  const fetchCities = async (stateId) => {
    try {
      const res = await httpPathName.get(`location/getcity/${stateId}`);
      setCities(res.data?.data);
      console.log("setCities",res);
    } catch (e) {
      console.error("Error fetching cities", e);
    }
  };


    // Handle country change and fetch related states
    const handleCountryChange = (e) => {
      const countryId = e.target.value;
      const countryName = countries.find((country) => country._id === countryId)?.name;  // Find name of selected country
      
      setSelectedCountryId(countryId);  // Set ID for filtering
      setSelectedCountryName(countryName);  // Set name for saving
      setFormData({ ...formData, country: countryName });  // Update form data with name

      fetchStates(countryId);  // Fetch states based on the selected country
      setCities([]); // Clear cities when country changes
    };

    // Handle state change and fetch related cities
    const handleStateChange = (e) => {
      const stateId = e.target.value;
      const stateName = states.find((state) => state._id === stateId)?.name;  // Find name of selected state

      setSelectedStateId(stateId);  // Set ID for filtering
      setSelectedStateName(stateName);  // Set name for saving
      setFormData({ ...formData, state: stateName });  // Update form data with name
      
      fetchCities(stateId); // Fetch cities based on the selected state
    };




  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
    fetchCountries(); 
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Clear previous content
    context.clearRect(0, 0, canvas.width, canvas.height);
    // Set text properties
    context.font = "30px Arial";
    context.fillStyle = "blue";
    context.textAlign = "center";
    context.textBaseline = "middle";
    // Draw CAPTCHA text
    context.fillText(expression, canvas.width / 2, canvas.height / 2);
  }, [expression]);

  function generateRandomKeyword() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let keyword = "";

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      keyword += characters.charAt(randomIndex);
      // setExpression(keyword);
    }

    return keyword;
  }

  // const uploadFile = () => {
  //   fileRef.current.click();
  // };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (
      formData?.mobile_number &&
      !isValidPhoneNumber(`+${formData?.mobile_number}`)
    ) {
      dispatch(setAlert("error", "Please enter a valid mobile number"));
    } else if (Object.keys(formData).length < 13) {
      dispatch(setAlert("error", "All fields are required!"));
    } else if (captcha !== expression) {
      dispatch(setAlert("error", "Please enter a valid Captcha Code"));
    } else {
      // formData.center_of_relative = JSON.parse(formData.center_of_relative);
      // formData.center_of_relative_of_direct_competition = JSON.parse(
      //   formData.center_of_relative_of_direct_competition
      // );
      formData.own_space = JSON.parse(formData.own_space);
      formData.relative = JSON.parse(formData.relative);
      formData.mobile_number = JSON.parse(formData.mobile_number);
      formData.pincode = JSON.parse(formData.pincode);
      const promise = await httpPathName
        .post(
          `partnerWithUs/postPartnerData`,
          { ...formData },
          { headers: { "x-auth-token": token } }
        )
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            dispatch(setAlert("success", response?.data?.message));
            formRef.current.reset();
            setCaptcha("");
          } else {
            dispatch(setAlert("error", response?.response?.data?.message));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAlert("error", err?.response?.data?.message));
        });
    }
  };

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Team up with us for promoting healthcare in the Philippines.","Discover ways to team up with us and contribute to advancing healthcare solutions. Join us in our mission to provide quality diagnostic services and improve patient outcomes.")

  return (
    <>
      <MetaDetails
        title="Team up with us for promoting healthcare in the Philippines."
        description="Discover ways to team up with us and contribute to advancing healthcare solutions. Join us in our mission to provide quality diagnostic services and improve patient outcomes."
      />

      <Header />
      <div className="partnerWithUs">
        <Wanner title="Partner with Us" image={partnerWithUs} dot={false} />

        <div className="partnerTitlemiddle">
          {/* <h1>Partner with Us</h1>
          <img src={dot3} alt="dot3" /> */}
          <p>
            We are happy to hear from you! Kindly fill in the below details and
            we will get back to you.
            <br />
            <p>Thanks!</p>
          </p>
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="formWrapper"
        >
          <p className="title">Business Details</p>
          <div className="business">
            <div className="OnelineData">
              <p>Enquiring for *</p>
              <select
                name="partner"
                required
                // value={formData?.gender}
                onChange={(e) => handleChange(e)}
                id="partner"
              >
                <option hidden value="">
                  Select Association
                </option>
                <option value="Franchisee">Franchisee</option>
                <option value="Hospital Lab Management (HLM)">
                  Hospital Lab Management (HLM)
                </option>
                <option value="Annual Physical Examination">
                  Annual Physical Examination
                </option>
                <option value="Pre-Employment Medical Examination (PEME)">
                  Pre-Employment Medical Examination (PEME)
                </option>
                <option value="Seafarers - PEME">Seafarers - PEME</option>
                <option value="RWA Camps">RWA Camps</option>
                <option value="Corporate Camps">Corporate Camps</option>
              </select>
            </div>
            <div className="input-wrapper">
              <div>
                <p>First Name *</p>
                <input
                  name="first_name"
                  // value={formData?.firstName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="First Name *"
                />
              </div>
              <div>
                <p>Last Name *</p>
                <input
                  name="last_name"
                  // value={formData?.lastName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Last Name *"
                />
              </div>
              <div>
                <p>Email Id *</p>
                <input
                  name="email_id"
                  // value={formData?.lastName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Email Id *"
                />
              </div>
              <div>
                <p>Mobile Number *</p>
                <input
                  name="mobile_number"
                  // value={formData?.lastName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Mobile No *"
                />
              </div>
              <div>
                <p>Address *</p>
                <input
                  name="address"
                  // value={formData?.lastName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Address *"
                />
              </div>
              <div>
                <p>Pin Code *</p>
                <input
                  name="pincode"
                  // value={formData?.lastName}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Pin Code *"
                />
              </div>
          {/* Country Dropdown */}
          <div>
            <p>Select Country *</p>
            <select
              id="country-select"
              name="country"
              value={selectedCountryId}
              onChange={handleCountryChange}
              aria-label="Select Country"
            >
              <option value="" disabled>Select Country</option>
              { countries && countries.length > 0 ? (
                countries.map((country) => (
                  <option key={country._id} value={country._id}>
                    {country.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>No countries available</option>
              )}
            </select>
          </div>


                {/* State Dropdown */}
                <div>
                  <p>Select State *</p>
                  <select
                    name="state"
                    value={selectedStateId}
                    onChange={handleStateChange}
                    required
                  >
     
                <option value="" disabled>Select State</option>
                  {states && states.length > 0 ? (
                    states.map((state) => (
                      <option key={state._id} value={state._id}>
                        {state.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>No States available</option>
                  )}
                  </select>
                </div>

                {/* City Dropdown */}
                <div>
                  <p>Select City *</p>
                  <select
                    name="city"
                    value={formData.city || ""}
                    onChange={handleChange}
                    required
                  >
  
                <option value="" disabled>Select City</option>
                  {cities&&cities.length > 0 ? (
                    cities.map((city) => (
                      <option key={city._id} value={city.name}>
                        {city.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>No City available</option>
                  )}
                  </select>
                </div>
              <div>
                <p>Ownership *</p>
                <select
                  required
                  name="ownership"
                  // value={formData?.gender}
                  onChange={(e) => handleChange(e)}
                  id="Ownership"
                >
                  <option hidden value="">
                    Select Ownership
                  </option>
                  <option value="Quezon">Quezon</option>
                </select>
              </div>

              <div>
                <p>Profession</p>
                <select
                  required
                  name="profession"
                  // value={formData?.gender}
                  onChange={(e) => handleChange(e)}
                  id="Profession"
                >
                  <option hidden value="">
                    Select Profession
                  </option>
                  <option value="Quezon">Quezon</option>
                </select>
              </div>

              {/* <div>
                <p>Select Association *</p>
                <select
                  name="association"
                  // value={formData?.gender}
                  onChange={(e) => handleChange(e)}
                  id="association"
                >
                  <option hidden value="">
                    Select Association
                  </option>
                  <option value="Collection Center">Collection Center</option>
                  <option value="Hospital Lab Management">
                    Hospital Lab Management
                  </option>
                  <option value="Organise Camps">Organise Camps</option>
                </select>
              </div> */}

              <div>
                <p>Do you have your own space? *</p>
                <div className="radioWrapper">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      name="own_space"
                      type="radio"
                      value={true}
                      onChange={(e) => handleChange(e)}
                    />
                    <span>Yes</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      name="own_space"
                      value={false}
                      onChange={(e) => handleChange(e)}
                    />
                    <span>No</span>
                  </div>
                </div>
              </div>

              <div>
                <p>
                  Do you have any relative working at Nederlands Diagnostics? *
                </p>
                <div className="radioWrapper">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      name="relative"
                      value={true}
                      onChange={(e) => handleChange(e)}
                    />
                    <span>Yes</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      name="relative"
                      value={false}
                      onChange={(e) => handleChange(e)}
                    />
                    <span>No</span>
                  </div>
                </div>
              </div>
              {/* <div>
                <p>
                  Does your family member/relative has any other center of
                  Agilus labs? *
                </p>
                <div className="radioWrapper">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      value={true}
                      name="center_of_relative"
                      onChange={(e) => handleChange(e)}
                    />
                    <span>Yes</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      value={false}
                      name="center_of_relative"
                      onChange={(e) => handleChange(e)}
                    />
                    <span>No</span>
                  </div>
                </div>
              </div> */}

              {/* <div>
                <p>
                  Does your family member/relative has any other center of
                  direct competition? *
                </p>
                <div className="radioWrapper">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      value={true}
                      name="center_of_relative_of_direct_competition"
                      onChange={(e) => handleChange(e)}
                    />
                    <span>Yes</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      value={false}
                      name="center_of_relative_of_direct_competition"
                      onChange={(e) => handleChange(e)}
                    />
                    <span>No</span>
                  </div>
                </div>
              </div> */}

              <div>
                <p>Enter The Captcha</p>
                <input
                  type="text"
                  value={captcha}
                  onChange={(e) => setCaptcha(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1%",
                    gap: "4%",
                  }}
                >
                  <canvas ref={canvasRef} width={120} height={40}></canvas>
                  <svg
                    style={{ cursor: "pointer" }}
                    onClick={() => setExpression(generateRandomKeyword())}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 8.5C5 5 8.51657 3.00122 11.9991 3.00122C16.9692 3.00122 20.9982 7.03039 20.9982 12.0006C20.9982 16.9708 16.9692 21 11.9991 21C8.51657 21 5.49605 19.0217 3.99995 16.1276M3 8.5V4.00006M3 8.5H7"
                      stroke="#131A29"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Partners;
