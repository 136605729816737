import React, { useEffect, useState } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import workinglab from "Assests/Images/woman-working-lab-with-microscope.png";
import vision from "Assests/Images/businessman-hand-holding-lightbulb-with-glowing-light-creative-smart-thinking-inspiration-innovation-with-network-concept 1.png";
import mission from "Assests/Images/people-using-digital-device-while-meeting 1.png";
import icon1 from "Assests/Images/icon1.svg";
import onlinedoctor from "Assests/Images/onlinedoc.jpg";

import httpPathName from "Global/Config/Config";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";

const About = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAbout = async () => {
    const apiStatus = await httpPathName
      .get(`admin/content/all-aboutus`)
      .then((response) => {
        console.log("respoinse", response);
        setData(response?.data?.Allcontent[0]);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetched Error"));
      });
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <><Header />
    <div className="about1">
      <div className="section5">
        <div className="content">
          <div className="content-left">
            <h1>Online Doctor Consultation</h1>
            {/* <img className="wanner3dot" src={dot3} alt="dot3" /> */}
            
            <p>Connect with experienced healthcare professionals from the comfort of your home. Whether you need medical advice or personalized diet plans, our doctors and dieticians are here to support your health and nutritional goals with expert guidance.</p>
          </div>

          <div className="img-container right">
            <img
              className=""
              src={onlinedoctor}
              alt="Online doctor" />
          </div>
        </div>
      </div>




    </div>
    <Footer />
    </>
    
  );
};

export default About;
